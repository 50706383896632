
@import url('https://cdn.syncfusion.com/ej2/material.css');


#cont{
    width: 100%;
}

.candidat-details {
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
    font-family: 'Arial', sans-serif;
  
  }
  
  .candidat-details h2 {
    text-align: center;
    color: #333;
  }
  
  .candidat-details ul {
    list-style-type: none;
    padding: 0;

  }
  
  .candidat-details li {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    font-size: 17px;
  }
  
  .candidat-details li:last-child {
    border-bottom: none;
  }
  
  .candidat-details li strong {
    color: #555;
  }
  